import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./Splash.css";


export default function Splash(src) {
  const [firstImageSrc, setFirstImageSrc] = useState("/logo/Logo_Orbit_Only.png");
  const [secondImgSrc, setSecondImgSrc] = useState("/logo/Logo_Orbit_Only.png");

  const handleAnimationComplete = () => {
    // Change the first image source after the transition is complete
    setFirstImageSrc("/logo/Logo_Orbit_Only.png");
    setSecondImgSrc("/logo/Logo_Orbit_Only.png")
  };


  return (
    <div className="bg-black fixed w-full h-screen left-0 top-0  flex justify-center items-center splash z-[1000]">
        <motion.div
          className="image-wrapper z-[1005] flex items-center justify-center" // Add flex class to the image-wrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{ marginLeft: "10vw" }}
          transition={{ duration: 0.5, delay: 0.2 }}
          onAnimationComplete={handleAnimationComplete} // Call the callback function after the animation is complete
        >
          <div className="md:w-50">
            <img
              src={firstImageSrc} // Use the state variable for image source
              alt="LogoBlessink"
              className="w-[16vw] md:w-[7vw]"
            />
          </div>
          <div className="md:w-50">
            {/* <img
              src={secondImgSrc}
              alt="BlessinkStudio"
              className="w-[21vw] md:w-[11vw]"
              loading="lazy"
            /> */}
                <p className="text-xl md:text-2xl font-bold text-white">
                  Orbit Universe
                </p>
          </div>
        </motion.div>

      <div style={{ marginLeft: "-8.5vw" }}>
        {/* Apply negative margin to move the second motion.div to the left */}
        <motion.div
          className="rounded-sm flex p-4 bg-[#3761AD]"
          initial={{ opacity: 0, scaleY: 30, scaleX: 40 }}
          animate={{ opacity: 1, scaleY: 5, scaleX: 10 }}
          transition={{ duration: 0.5, ease: "easeIn", delay: 0.7 }}
          style={{
            width: "20vw", // Use vw for width to make it responsive
            height: "20vh", // Use vh for height to make it responsive
          }}
        >
          {/* The content inside the main motion.div */}
        </motion.div>
      </div>
    </div>
  );
}
