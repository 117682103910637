import React, { useState } from "react";
import "./About.css";
import { useInView } from "react-intersection-observer";
import Curved from "../Carousel/Curved/Curved";
import { CSSTransition } from "react-transition-group";

export default function Abouts() {
  return (
    <div className="flex flex-col">
      <div className="w-full aspect-w-16 aspect-h-9 rounded-xl overflow-hidden mb-8 drop-shadow-2xl">
        <img
          src="/images/Banner.png"
          className="object-cover w-full h-full"
          alt="Banner Image"
        />
      </div>
      <div className="">
        <div className="flex flex-col space-y-4 h-auto overflow-hidden">
          <p className="text-justify text-[12px] md:text-[2.1vw] font-bold md:mb-8">
            Orbit Universe is a holding company, we create all kinds of
            creatives such as production advertisements, art direction, creative
            idea, company profile, content development, and featured film.
          </p>
          <p className="text-justify text-[9px] md:text-[1.4vw] font-lighter">
            CGsick is a part of the Orbit Universe that focuses on creating
            content on social media. CGsick consist of passionate VFX & CGI team
            with a mission to not only create stunning visuals but also to
            assist you in optimizing your campaigns and achieving your business
            goals.
          </p>
        </div>
      </div>
    </div>
  );
}
