import React, { useRef, useState, useEffect } from "react";
import "./InquiryForm.css";
import HubspotInquiryForm from "../../components/Form/Inquiry/HubspotInquiryForm";
import Footer from "../../components/Footer/Footer";

const InquiryForm = () => {
  const sectionRefs = {
    home: useRef(null),
    service: useRef(null),
    project: useRef(null),
    about: useRef(null),
    inquiry: useRef(null)
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    // <div>
    //   <div className="flex justify-center items-center h-screen m-7">
    //     <div className="flex flex-col md:flex-row w-full md:w-3/4">
    //       {/* Left Section */}
    //       <div className="w-1/2 p-4">

    //         <div className="flex flex-col gap-y-4 items-start justify-center md:justify-start text-black">
    //         <div className="flex items-center space-x-2 md:space-x-4 text-[9px] md:text-[2.5vw] font-black">
    //             <div className="flex flex-col items-start">
    //               <a href="/">
    //                 <img
    //                   src="/logo/Logo_Orbit.png"
    //                   alt="LogoBlessink"
    //                   className="w-[45vw] md:w-[120px] lg:w-[180px] md:max-w-full min-[1440px]:w-[220px]"
    //                   id="footer-logo-blessink"
    //                 />
    //               </a>
    //             </div>
    //           </div>
    //           <div className="flex items-center ml-4 text-[9px] md:text-[2.5vw] font-black">
    //             <div className="flex flex-col items-start">
    //               <label className="">Contact Us</label>
    //               <label className="overflow-auto text-[4px] md:text-[1.5vw]">
    //                 <a href="mailto:info@orbituniverse.id">
    //                   info@orbituniverse.id
    //                 </a>
    //               </label>
    //             </div>
    //           </div>

    //           <div className="flex items-center ml-4 text-[9px] md:text-[2.5vw] font-black">
    //             <div className="flex flex-col items-start">
    //               <label className="">Call Us</label>
    //               <label className="text-[4px] md:text-[1.5vw]">
    //                 <a href="tel:+6282210201475">+62 82210201475</a>
    //               </label>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       {/* Right Section (InquiryEmbeded) */}
    //       <div className="w-1/2">
    //         <HubspotInquiryForm region="na1" portalId="45517899" formId="69277d9c-27a6-472c-94af-86ed8f61f894" />
    //       </div>
    //     </div>
    //   </div>

    //   {/* <Footer sectionRefs={sectionRefs} onClick={scrollToSection} /> */}

    // </div>

    <main className="font-montserrat">
      <div className="flex md:justify-between flex-col md:flex-row md:items-center md:px-[14vw] py-8">
        <div className="flex md:gap-5 md:flex-row flex-col md:w-full w-1/2 mx-auto md:gap-y-0 gap-y-5 py-4">
        <div className="flex flex-col gap-y-4 items-start justify-center md:justify-start text-black">
            <div className="flex items-center space-x-2 md:space-x-4 text-[9px] md:text-[3vw] font-black">
                <div className="flex flex-col items-start">
                  <a href="/">
                    <img
                      src="/logo/Logo_Orbit.png"
                      alt="LogoBlessink"
                      className="w-[45vw] md:w-[160px] lg:w-[180px] md:max-w-full min-[1440px]:w-[220px]"
                      id="footer-logo-blessink"
                    />
                  </a>
                </div>
              </div>
              <div className="flex items-center ml-4 text-[17px] md:text-[3vw] font-black">
                <div className="flex flex-col items-start">
                  <label className="">Contact Us</label>
                  <label className="overflow-auto text-[10px] md:text-[1.5vw]">
                    <a href="mailto:info@orbituniverse.id">
                      info@orbituniverse.id
                    </a>
                  </label>
                </div>
              </div>

              <div className="flex items-center ml-4 text-[17px] md:text-[3vw] font-black">
                <div className="flex flex-col items-start">
                  <label className="">Call Us</label>
                  <label className="text-[10px] md:text-[1.5vw]">
                    <a href="tel:+6282210201475">+62 82210201475</a>
                  </label>
                </div>
              </div>
            </div>
        </div>
        <div className="flex md:gap-5  flex-col md:w-full w-1/2 mx-auto md:gap-y-0 gap-y-5">
          <HubspotInquiryForm region="na1" portalId="45517899" formId="69277d9c-27a6-472c-94af-86ed8f61f894" />
        </div>
      </div>
      <Footer sectionRefs={sectionRefs} hide={true} onClick={scrollToSection} />
    </main>
  );
};

export default InquiryForm;