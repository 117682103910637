export default function WhatsApp(){
  return (
    <div className="fixed flex flex-col gap-3 items-center right-0 md:right-[3vw] bottom-[2.5vw] z-[999]">
      <a
        href="https://wa.me/6282210201475"
        target="_blank"
        className="block"
      >
        <div className="flex items-center px-5 py-1 rounded">
          <img
            src="/icon/icon-email/whatsapp.png"
            alt="logowhatsapp"
            className="max-[767px]:max-h-[10vw] max-h-[3vw]"
          />
        </div>
      </a>
      <a
        href="https://www.instagram.com/cgsick/"
        target="_blank"
        className="block"
      >
        <img
          src="/icon/icon-email/instagram.png"
          alt="logowhatsapp"
          className="max-[767px]:max-h-[10vw] max-h-[3vw]"
        />
      </a>
      <a
        href="https://www.tiktok.com/@cgsick_"
        target="_blank"
        className="block"
      >
        <img
          src="/icon/icon-email/Tiktok.svg"
          alt="logoTiktok"
          className="max-[767px]:max-h-[10vw] max-h-[3vw] mx-[1vw]"
        />
      </a>
      {/* <a
        href="https://wa.me/6282210201475"
        target="_blank"
        className="block md:hidden"
      >
        <div className="flex items-center">
          <img
            src="/icon/icon-email/whatsapp.png"
            alt="logowhatsapp"
            className="max-[767px]:max-h-[10vw] max-h-[5vw]"
          />
        </div>
      </a> */}
    </div>
  );
}