// export const Clients = [
//     // {
//     //   image: "/client/kominfo.png",
//     //   url: 'https://www.kominfo.go.id/',
//     //   pd: '3vw'
//     // },
//     // {
//     //   image: "/client/saison.jpg",
//     // },
//     {
//       image: "/client/bpjskesehatan_new.png",
//       url: 'https://www.bpjs-kesehatan.go.id/',
//       pd: '0.2vw'
//     },
//     // {
//     //   image: "/client/perpusnas.png",
//     //   url: 'https://www.perpusnas.go.id/',
//     //   pd: '3vw'
//     // },
//     {
//       image: "/client/pertamina.png",
//       url: 'https://mypertamina.id/',
//       pd: '2vw'
//     },
//     // {
//     //   image: "/client/visinema_new.png",
//     //   url: 'https://visinema.co/',
//     //   pd: '1vw'
//     // },
//     {
//       image: "/client/hni.webp",
//       url: 'https://hni.net/',
//       pd: '1vw'
//     },
//     // {
//     //   image: "/client/rumahyatim_new.png",
//     //   url: 'https://rumah-yatim.org/',
//     //   pd: '0vw'
//     // },
//     // {
//     //   image: "/client/bareskrim_new.png",
//     //   url: 'https://polri.go.id/',
//     //   pd: '3vw'
//     // },
//     // {
//     //   image: "/client/idnmedia.png",
//     //   url: 'https://www.idn.media/',
//     //   pd: '2vw'
//     // },
//     {
//       image: "/client/kementrianpertahanan.png",
//       url: 'https://www.kemhan.go.id/',
//       pd: '3vw'
//     },
//     {
//       image: "/client/mitsubishi.png",
//       url: 'https://www.mitsubishi-motors.co.id/',
//       pd: '3vw'
//     },
//     // {
//     //   image: "/client/RAPI.png",
//     //   url: 'https://rapifilms.com/',
//     //   pd: '2vw'
//     // },
//     {
//       image: "/client/screenplayfilm.webp",
//       url: 'http://www.screenplayfilms.com/',
//       pd: '3vw'
//     },
//     {
//       image: "/client/waskita.png",
//       url: 'https://www.waskita.co.id/',
//       pd: '3vw'
//     },
//     {
//       image: "/client/allianz.png",
//       url: 'https://www.allianz.co.id/',
//       pd: '2vw'
//     },
//     // {
//     //   image: "/client/qudwah.png",
//     // },
//     // {
//     //   image: "/client/DC.png",
//     //   url: 'https://dci-indonesia.com/',
//     //   pd: '3vw'
//     // },
//     // {
//     //   image: "/client/satriadewa.webp",
//     //   url: '',
//     //   pd: '3vw'
//     // },
//     // {
//     //   image: "/client/kasihpalestina.png",
//     //   url: 'https://kasihpalestina.com/',
//     //   pd: '3vw'
//     // },
//     // {
//     //   image: "/client/bukitswiss.png", 
//     // },
//     {
//       image: "/client/grabfood.png",
//       url: 'https://food.grab.com/',
//       pd: '2vw'
//     },
//     // {
//     //   image: "/client/cintaquran.png",
//     // },
//     // {
//     //   image: "/client/merc.png",
//     //   url: 'https://www.mercedes-benz.co.id/passengercars.html',
//     //   pd: '3vw'
//     // },
//   ];
export const Clients = [
    {
      image: "/client/GRABEXPRESS.png",
      url: 'https://www.grab.com/id/express/',
      pd: '3vw'
    },
    {
      image: "/client/CAFFINO.png",
      url: 'https://caffino.id/',
      pd: '3vw'
    },
    {
      image: "/client/BSI.png",
      url: 'https://www.bankbsi.co.id/',
      pd: '3vw'
    },
    {
      image: "/client/MITSUBISHI ELECTRIC.png",
      url: 'https://id.mitsubishielectric.com/id/',
      pd: '3vw'
    },
    {
      image: "/client/BUMN.png",
      url: 'https://www.bumn.go.id/',
      pd: '3vw'
    },
    {
      image: "/client/ALLIANZ.png",
      url: 'https://www.allianz.co.id/',
      pd: '3vw'
    },
];