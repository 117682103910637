import React, { useRef } from "react";
import { ServicesData } from "../../lib/ServicesData";
import Service from "../../components/Services/Service";
import { motion, useInView } from "framer-motion";

export default function Services(props) {
  const ref = useRef();
  const inView = useInView(ref, { once: true });
  return (
    <div className="flex flex-col  w-full relative px-10 md:px-0 pb-4" ref={ref}>
      <motion.div
        className="flex absolute top-0 right-0 w-[2vw] h-full bg-[#213164]"
        initial={{ height: 0 }}
        animate={{ height: inView ? `100%` : 0 }}
        transition={{ duration: inView ? 5 : 0.3 }}
      ></motion.div>
      <div className="flex items-center md:px-[14vw]">
        <div>
          <motion.div
            className="flex items-start md:space-x-6"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: inView ? 0 : 50, opacity: inView ? 1 : 0 }}
            transition={{ duration: inView ? 0.5 : 0.3 }}
          >
            <p className="text-[14px] md:text-[2.9vw] title-section font-bold uppercase">
              <strong>What do we do ?</strong>
            </p>
          </motion.div>
        </div>
      </div>
      <div className="flex flex-col w-full gap-y-10 my-8 md:px-[14vw]">
        {ServicesData.map((service, index) => {
          let numbering = (index + 1).toString().padStart(2, "0") + ".";
          return (
            <Service
              odds={index % 2 === 0}
              {...service}
              numbering={numbering}
              key={index}
            ></Service>
          );
        })}
      </div>
    </div>
  );
}
