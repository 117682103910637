import React from "react";
import { ReactComponent as Sun } from "./Sun.svg";
import { ReactComponent as Moon } from "./Moon.svg";
import "./DarkMode.css";
import { SocialMedias } from "../../../lib/SocialMediaData";

const DarkMode = () => {
  const setDarkTheme = () => {
    document.querySelector("body").setAttribute("data-theme", "dark");
    // var mail = document.getElementById("footer-img-mail");
    // var phone = document.getElementById("footer-img-phone");
    // var location = document.getElementById("footer-img-location");
    // var logo = document.getElementById("footer-logo-blessink");
    // var textLogo = document.getElementById("footer-text-blessink");

    // mail.src = "/icon/mail-dark.png";
    // phone.src = "/icon/phone-dark.png";
    // location.src = "/icon/location-dark.png";
    // logo.src = "/logo/Logo-blessink-dark.png";
    // textLogo.src = "/logo/Text-blessink-dark.png";

    // SocialMedias.map((socmed, idx) => {
    //   var medsoc = document.getElementById("footer-socmed-" + idx);
    //   medsoc.src = socmed.dark_image;
    //   medsoc.alt = socmed.dark_image;
    // });
  };

  const setLightTheme = () => {
    document.querySelector("body").setAttribute("data-theme", "light");
    // var mail = document.getElementById("footer-img-mail");
    // var phone = document.getElementById("footer-img-phone");
    // var location = document.getElementById("footer-img-location");
    // var logo = document.getElementById("footer-logo-blessink");
    // var textLogo = document.getElementById("footer-text-blessink");

    // mail.src = "/icon/mail.png";
    // phone.src = "/icon/phone.png";
    // location.src = "/icon/location.png";
    // logo.src = "/logo/Logo-blessink.png";
    // textLogo.src = "/logo/Text-blessink.png";

    // SocialMedias.map((socmed, idx) => {
    //   var medsoc = document.getElementById("footer-socmed-" + idx);
    //   medsoc.src = socmed.image;
    //   medsoc.alt = socmed.image;
    // });
  };

  const toogleOnChange = (e) => {
    if (e.target.checked) setDarkTheme();
    else setLightTheme();
  };

  return (
    <div className="dark_mode">
      <input
        className="dark_mode_input"
        type="checkbox"
        id="darkmode-toggle"
        onChange={toogleOnChange}
      />
      <label className="dark_mode_label" htmlFor="darkmode-toggle">
        <Moon />
        <Sun />
      </label>
    </div>
  );
};

export default DarkMode;
