// export const ServicesData = [
//   {
//     image: "/images/CGI.png",
//     url: `https://www.youtube.com/@blessinkstudio4141`,
//     title: "Computer Generated Imagery (CGI)",
//     description: `CGI (Computer-Generated Imagery), is the digital artistry that brings visuals to life. We leverage powerful software and skilled artists to create stunning virtual worlds, characters, and objects, turning your imagination into reality.`,
//     customimg: `absolute -top-10`,
//   },
//   {
//     image: "/images/VFX.png",
//     url: `https://www.youtube.com/@blessinkstudio4141`,
//     title: "Visual Effect (VFX)",
//     description: `Visual effects involve creating or manipulating film images to enhance the realism in visual storytelling.`,
//     customimg: `absolute -top-4 md:-top-20`,
//   },
//   {
//     image: "/images/CONTENT CREATION.png",
//     url: `https://www.youtube.com/@blessinkstudio4141`,
//     title: "Content Creation",
//     description: `Stand out on social platforms with
//         thumb-stopping content that sparks conversations and drives engagement.`,
//     customimg: `scale-[1.1] absolute top-0 right-2 md:right-5`,
//   },
//   {
//     image: "/images/EVENT BRANDING.png",
//     url: `https://www.youtube.com/@blessinkstudio4141`,
//     title: "Event Branding",
//     description: `Let' s collaborate to create an unforgettable experience for your customers with us.`,
//     customimg: `absolute -bottom-10 md:-bottom-20`,
//   },
//   {
//     image: "/images/CREATIVE CAMPAIGN.png",
//     url: `https://www.youtube.com/@blessinkstudio4141`,
//     title: "Creative Campaign",
//     description: `Let's innovate together to captivate your audience in ways never seen before.`,
//     customimg: `absolute -top-4 md:-top-20`
//   },
//   {
//     image: "/images/PRODUCT VIDEO.png",
//     url: `https://www.youtube.com/@blessinkstudio4141`,
//     title: "Product Video",
//     description: `Build customer trust in the products you offer by presenting them in a more professional manner.`,
//     customimg: `absolute right-0 -top-6 md:-top-12`
//   },
// ];
export const ServicesData = [
  {
    image: "/images/COMPRESSED/CGI.png",
    url: `https://www.youtube.com/@blessinkstudio4141`,
    title: "Computer Generated Imagery (CGI)",
    description: `CGI (Computer-Generated Imagery), is the digital artistry that brings visuals to life. We leverage powerful software and skilled artists to create stunning virtual worlds, characters, and objects, turning your imagination into reality.`,
    customimg: `absolute -top-10`,
  },
  {
    image: "/images/COMPRESSED/VFX.png",
    url: `https://www.youtube.com/@blessinkstudio4141`,
    title: "Visual Effect (VFX)",
    description: `Visual effects involve creating or manipulating film images to enhance the realism in visual storytelling.`,
    customimg: `absolute -top-4 md:-top-20`,
  },
  {
    image: "/images/COMPRESSED/CONTENT CREATION.png",
    url: `https://www.youtube.com/@blessinkstudio4141`,
    title: "Content Creation",
    description: `Stand out on social platforms with
        thumb-stopping content that sparks conversations and drives engagement.`,
    customimg: `scale-[1.1] absolute top-0 right-2 md:right-5`,
  },
  {
    image: "/images/COMPRESSED/EVENT BRANDING.png",
    url: `https://www.youtube.com/@blessinkstudio4141`,
    title: "Event Branding",
    description: `Let' s collaborate to create an unforgettable experience for your customers with us.`,
    customimg: `absolute -bottom-10 md:-bottom-20`,
  },
  {
    image: "/images/COMPRESSED/CREATIVE CAMPAIGN.png",
    url: `https://www.youtube.com/@blessinkstudio4141`,
    title: "Creative Campaign",
    description: `Let's innovate together to captivate your audience in ways never seen before.`,
    customimg: `absolute -top-4 md:-top-20`
  },
  {
    image: "/images/COMPRESSED/PRODUCT VIDEO.png",
    url: `https://www.youtube.com/@blessinkstudio4141`,
    title: "Product Video",
    description: `Build customer trust in the products you offer by presenting them in a more professional manner.`,
    customimg: `absolute right-0 -top-6 md:-top-12`
  },
];