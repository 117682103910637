import React, { useEffect } from "react";

const ImagePreloader = ({ src }) => {
  useEffect(() => {
    const image = new Image();
    image.src = src;
    window[src] = image;
  }, [src]);

  return null;
};

export default ImagePreloader;
