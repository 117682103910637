import React from "react";

export default function ProjectDetail(props) {
  return (
    <React.Fragment>
      {/* <div className="flex flex-col">
        <span className="text-5xl uppercase italic font-bold">{props.title}</span>
        <span className="text-3xl">{props.description}</span>
      </div> */}
      {props.children}
    </React.Fragment>
  );
}
