import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SocialMedias } from "../../lib/SocialMediaData";
import "./Footer.css";
export default function Footer(props) {
  const menu = [
    {
      url: "/",
      title: "Home",
      ref: props.sectionRefs.home,
    },
    {
      url: "/service",
      title: "Service",
      ref: props.sectionRefs.service,
    },
    {
      url: "/project",
      title: "Project",
      ref: props.sectionRefs.project,
    },
    {
      url: "/inquiry",
      title: "Inquiry",
      ref: props.sectionRefs.inquiry,
    },
  ];
  const menu2 = [
    {
      url: "/about",
      title: "About",
      ref: props.sectionRefs.about,
    },
    {
      url: "/inquiry",
      title: "Inquiry",
      ref: props.sectionRefs.inquiry,
    },
  ];
  return (
    <div className="border-t-1 bg-footer">
      <div className="container px-14 md:px-20 pt-4">
        <div className="grid md:grid-cols-4 gap-4">
          <div className="flex flex-col md:justify-start ">
            <div className="flex justify-start md:w-50">
              <img
                src="/logo/Logo_Orbit_White.png"
                alt="LogoBlessink"
                className="w-[40vw] md:w-[105px] lg:w-[160px] md:max-w-full min-[1440px]:w-[180px]"
                id="footer-logo-blessink"
              />
            </div>
            <div className="flex justify-start gap-x-2 text-[#ffffff]">
              {SocialMedias.map((socmed, idx) => (
                <a href={socmed.url} key={idx}>
                  <FontAwesomeIcon
                    icon={socmed.icon}
                    id={"footer-socmed-" + idx}
                    className="max-[767px]:max-h-[5vw] max-h-[3vw] w-[6vw] md:w-[2.5vw]"
                    size="2x"
                  />
                </a>
              ))}
            </div>
          </div>
          {props.hide ? null : (
            <div className="flex flex-col items-start gap-y-4 justify-center md:justify-start text-footer">
              <div className="font-bold">Support</div>
              <div>
                <ul className="flex flex-col menu-link max-w-max  text-footer-content gap-y-2">
                  {menu.map((men, index) => (
                    <li key={index}>
                      <a
                        onClick={(event) => {
                          props.onClick(men.ref);
                        }}
                        className="hover:cursor-pointer "
                        href={`#${men.title}`}
                      >
                        {men.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-y-4 items-start justify-center md:justify-start text-footer">
            <div className="font-bold">Orbit Universe</div>
            <div className="flex items-center space-x-2 md:space-x-4 text-footer-content">
              <a
                onClick={(event) => {
                  props.onClick(props.sectionRefs.about);
                }}
                className="hover:cursor-pointer "
                href={`#about`}
              >
                {`About Us`}
              </a>
            </div>
            <div className="flex items-center space-x-2 md:space-x-4 text-footer">
              <div className="flex flex-col items-start">
                <label className="">Mail Us</label>
                <label className="text-footer-content overflow-auto">
                  <a href="mailto:info@orbituniverse.id">
                    info@orbituniverse.id
                  </a>
                </label>
              </div>
            </div>
            <div className="flex items-center  space-x-2 md:space-x-4 ">
              <div className="flex flex-col items-start">
                <label className="">Call Us</label>
                <label className="text-footer-content">
                  <a href="tel:+6282210201475">+62 82210201475</a>
                </label>
              </div>
            </div>
          </div>
          <div className="flex items-start space-x-2 md:space-x-4 text-footer">
            <div className="flex flex-col items-start">
              <label className="font-bold">Find Us</label>
              <label className="text-footer-content overflow-auto">
                <a href="https://maps.app.goo.gl/Sz3Yt9pvQwreiGXQ7 " className="">
                  Jl. Hwarang No.12, RT.1/RW.3, Setu, Kec. Cipayung, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13880
                </a>
              </label>
            </div>
          </div>
        </div>
        <div className="pt-[2rem]">
          <hr className="border-footer" />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pt-2">
          <div className="flex justify-end md:col-start-3 mt-1 md:text-sm max-[767px]:text-[2.2vw]">
            &copy; 2023 Orbit Universe. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
