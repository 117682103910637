import logo from "./logo.svg";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./views/Home/Home";
import InquiryForm from "./views/Form/InquiryForm";
import { BrowserRouter as Router, Route, Routes, HashRouter } from "react-router-dom";
import AddLibrary from "./components/Helper/AddLibrary";

const App = () => {
  return (
    <div className="app-config">
      <HashRouter>
        {/* <Navbar /> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/inquiry" element={<InquiryForm />} />
        </Routes>
        {/* <Footer /> */}
      </HashRouter>
    </div>
  );
};

export default App;
