import React, {useEffect} from "react";
import emailjs from '@emailjs/browser';
    
const HubspotInquiryForm = props => {
    const { region, portalId, formId } = props;

    useEffect(() => {
        const script = document.createElement('script');
        script.src='//js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: region,
                    portalId: portalId,
                    formId: formId,
                    target: '#hubspotForm',
                    onFormSubmit: function($form) {
                        // Fetch form submission data
                        // const submission = $form.context.dataset.submission;
                        console.log('Form submitted with data:',$form);
                        
                        emailjs.sendForm('service_ri6k9ms', 'template_21gqw9u', $form, 'O4kAO6gTB_0rWHwbE')
                        .then(console.info("Your inquiry has been sent"))
                        .catch((err) => {
                            console.info(err);
                          });
                          debugger;
                        // clearForm(e);
                    }
                })
            }
        });
    }, []);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    );
};

export default HubspotInquiryForm;