import { useRef } from "react";
import { Clients } from "../../lib/ClientData";
import { motion, useInView } from "framer-motion";
import "./Clients.css";
export default function Client(props) {
  const chunks = Math.ceil(Clients.length / 3);
  const rows = Array.from({ length: chunks }, (_, i) =>
    Clients.slice(i * 3, i * 3 + 3)
  );
  const ref = useRef();
  const inView = useInView(ref, { once: true });
  const onClickHandler = (url) => {
    if (url !== "" && url !== null) {
      window.open(url, "_blank");
    }
  };
  return (
    <div className="flex flex-col w-full relative px-10 md:px-0" ref={ref}>
      <motion.div
        className="flex absolute top-0 right-0 w-[2vw] h-full bg-[#213164]"
        initial={{ height: 0 }}
        animate={{ height: inView ? `100%` : 0 }}
        transition={{ duration: inView ? 5 : 0.3 }}
      ></motion.div>
      <div className="flex space-x-4 items-center md:px-[14vw]">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: inView ? 0 : 50, opacity: inView ? 1 : 0 }}
          transition={{ duration: inView ? 0.5 : 0.3 }}
        >
          <div className="flex items-start space-x-6">
            <span className="text-[14px] md:text-[2.9vw]  title-section font-bold uppercase">
              We’re working with the best client
            </span>
          </div>
          <div className="text-[11px] md:text-[2.3vw]">
            Client who have used the services in Orbit Universe
          </div>
        </motion.div>
      </div>
      <div className="flex w-full justify-center overflow-visible my-4 md:px-[14vw]">
        <table className="table w-full overflow-visible">
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((item, index) => (
                  <td
                    key={index}
                    className="whitespace-nowrap border-2 td-color dark-bg-white"
                    width={"20%"}
                  >
                    <motion.div
                      className="w-full flex justify-center relative overflow-visible"
                      whileHover={{
                        backgroundColor: `#3761AD`,
                        borderRadius: `2%`,
                        cursor: "pointer",
                      }}
                      onClick={() => onClickHandler(item.url)}
                    >
                      <img
                        src={item.image}
                        alt=""
                        className="max-w-full h-[20vw] object-contain"
                        style={{ padding: item.pd }}
                      />
                      {/* <div className="justify-center absolute top-0 left-0 w-full opacity-0 hover:opacity-100 hover:z-10 hover:flex hover:scale-110 hover:bg-slate-500 hover:rounded-xl">
                            <img src={item.image} alt="" className="w-[15vw]" />
                          </div> */}
                    </motion.div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
