import React, { useRef } from "react";
import "./Service.css";
import { motion, useInView } from "framer-motion";
export default function Service(props) {
  // const [isPlayed, setIsPlayed] = useState(false);
  // const VideoRef = useRef();
  const HandleVideoClick = () => {
    // if (!isPlayed) {
    //   VideoRef.current.play();
    // } else {
    //   VideoRef.current.pause();
    // }
    // setIsPlayed((prevState) => !prevState);
  };
  // const HandleVideoEnded = () => {
  //   VideoRef.current.currentTime = 0;
  //   setIsPlayed((prevState) => !prevState);
  // };

  const elements = {
    video: {
      variants: {
        initial: { opacity: 0, y: 100 },
        animate: { opacity: 1, y: 0 },
      },
    },
    numbering: {
      variants: {
        initial: { y: 100, opacity: 0 },
        animate: { y: 0, opacity: 1 },
      },
    },
    title: {
      variants: {
        initial: { opacity: 0, y: 100 },
        animate: { opacity: 1, y: 0 },
      },
    },
    breakline: {
      variants: {
        initial: { opacity: 0, transform: "scaleX(0)" },
        animate: { opacity: 1, transform: "scaleX(1)" },
      },
    },
    description: {
      variants: {
        initial: { opacity: 0, y: 100 },
        animate: { opacity: 1, y: 0 },
      },
    },
  };
  const ref = useRef();
  const inView = useInView(ref);
  return (
    <div className="flex gap-x-5 md:gap-x-20 md:min-h-[16rem]" ref={ref}>
      {props.odds && (
        <React.Fragment>
          <motion.div
            className="flex justify-end items-center"
            initial={`initial`}
            animate={inView ? `animate` : `initial`}
            variants={elements.video.variants}
            transition={inView ? { duration: 1 } : { duration: 0.3 }}
          >
            <div className="w-[35vw] h-[40vw] overflow-hidden skew-x-[5deg] rounded-lg hover:scale-110 transition-all">
              <img
                src={props.image}
                alt={props.image}
                className={`max-w-[35vw] object-contain ${props.customimg}`}
              ></img>
              {/* <video
                  className="w-full h-full object-cover"
                  onClick={HandleVideoClick}
                  ref={VideoRef}
                  onEnded={HandleVideoEnded}
                >
                  <source src={props.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
            </div>
          </motion.div>
          <div className="flex flex-col">
            <motion.span
              className="text-[2vw]"
              initial={`initial`}
              animate={inView ? `animate` : `initial`}
              variants={elements.numbering.variants}
              transition={
                inView
                  ? { duration: 0.5, delay: 0.7 }
                  : { duration: 0.3, delay: 0 }
              }
            >
              {/* {props.numbering} */}
            </motion.span>
            <div className="flex flex-col w-fit">
              <motion.span
                className="text-[12px] md:text-[2.5vw] font-bold"
                initial={`initial`}
                animate={inView ? `animate` : `initial`}
                variants={elements.title.variants}
                transition={
                  inView
                    ? { duration: 0.5, delay: 0.7 }
                    : { duration: 0.3, delay: 0 }
                }
              >
                {props.title}
              </motion.span>
              <div className="relative my-4">
                <motion.div
                  className="absolute top-0 left-0 h-[5px] bg-[#3761AD] z-20"
                  style={{
                    width: `calc(35vw)`,
                    transform: "scaleX(0)",
                    transformOrigin: "left",
                  }}
                  initial={`initial`}
                  animate={inView ? `animate` : `initial`}
                  variants={elements.breakline.variants}
                  transition={
                    inView
                      ? { duration: 0.5, delay: 1.2 }
                      : { duration: 0.3, delay: 0 }
                  }
                ></motion.div>
              </div>
            </div>
            <motion.div
              className="text-[8.5px] md:text-[1.5vw]"
              initial={`initial`}
              animate={inView ? `animate` : `initial`}
              variants={elements.description.variants}
              transition={
                inView
                  ? { duration: 0.3, delay: 1.7 }
                  : { duration: 0.3, delay: 0 }
              }
            >
              {props.description}
            </motion.div>
          </div>
        </React.Fragment>
      )}
      {!props.odds && (
        <React.Fragment>
          <div className="flex flex-col items-end w-full">
            <motion.span
              className="text-[2vw]"
              initial={`initial`}
              animate={inView ? `animate` : `initial`}
              variants={elements.numbering.variants}
              transition={
                inView
                  ? { duration: 0.5, delay: 0.7 }
                  : { duration: 0.3, delay: 0 }
              }
            >
              {/* {props.numbering} */}
            </motion.span>
            <div className="flex flex-col w-fit items-end">
              <motion.span
                className="text-[12px] md:text-[2.5vw] font-bold relative text-right"
                initial={`initial`}
                animate={inView ? `animate` : `initial`}
                variants={elements.title.variants}
                transition={
                  inView
                    ? { duration: 0.5, delay: 0.7 }
                    : { duration: 0.3, delay: 0 }
                }
              >
                {props.title}
              </motion.span>
              <div className="relative my-4 w-full">
                <motion.div
                  className="absolute top-0 right-0 h-[5px] bg-[#3761AD] z-20"
                  style={{
                    width: `calc(35vw)`,
                    transform: "scaleX(0)",
                    transformOrigin: "right",
                  }}
                  initial={`initial`}
                  animate={inView ? `animate` : `initial`}
                  variants={elements.breakline.variants}
                  transition={
                    inView
                      ? { duration: 0.5, delay: 1.2 }
                      : { duration: 0.3, delay: 0 }
                  }
                ></motion.div>
              </div>
            </div>
            <motion.span
              className="text-right text-[8.5px] md:text-[1.5vw]"
              initial={`initial`}
              animate={inView ? `animate` : `initial`}
              variants={elements.description.variants}
              transition={
                inView
                  ? { duration: 0.3, delay: 1.7 }
                  : { duration: 0.3, delay: 0 }
              }
            >
              {props.description}
            </motion.span>
          </div>
          <motion.div
            className="flex items-center justify-start w-full"
            initial={`initial`}
            animate={inView ? `animate` : `initial`}
            variants={elements.video.variants}
            transition={inView ? { duration: 1 } : { duration: 0.3 }}
          >
            <div className="w-[35vw] h-[44vw] overflow-hidden -skew-x-[5deg] rounded-lg hover:scale-110 transition-all">
              <img
                src={props.image}
                alt={props.image}
                className={`max-w-[35vw] object-contain ${props.customimg}`}
              ></img>
              {/* <video
                  className="w-full h-full object-cover"
                  onClick={HandleVideoClick}
                  ref={VideoRef}
                  onEnded={HandleVideoEnded}
                >
                  <source src={props.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
            </div>
          </motion.div>
        </React.Fragment>
      )}
    </div>
  );
}
