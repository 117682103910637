import React, { useEffect, useRef, useState } from "react";
import ProjectList from "./ProjectList";
import ProjectDetail from "./ProjectDetail";
import { Projects } from "../../lib/ProjectsData";
import CardProject from "../../components/Project/Card";
import { motion, useInView } from "framer-motion";
import RotationButton from "../Button/RotationButton";

export default function Project(props) {
  const ref = useRef();
  const inView = useInView(ref, { once: true });
  const projectRef = useRef();
  const projectInView = useInView(projectRef);
  const [projectLength, setProjectLength] = useState(9);
  const [isShowMore, setIsShowMore] = useState(0);

  const HandlerButtonClick = () => {
    setIsShowMore((prevState) => (prevState === 1 ? -1 : 1));
  };

  useEffect(() => {
    if (isShowMore === 1) setProjectLength(Projects.at(0).projectList.length);
    else {
      setProjectLength(9);
      if (isShowMore !== 0)
        projectRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isShowMore]);

  return (
    <div
      className="flex flex-col w-full relative px-10 md:px-0 py-8 "
      ref={ref}
    >
      <motion.div
        className="flex absolute top-0 left-0 w-[2vw] h-full bg-[#213164]"
        initial={{ height: 0 }}
        animate={{ height: inView ? `100%` : 0 }}
        transition={{ duration: inView ? 5 : 0.3 }}
      ></motion.div>
      <div className="flex items-center md:px-[14vw]">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: inView ? 0 : 50, opacity: inView ? 1 : 0 }}
          transition={{ duration: inView ? 0.5 : 0.3 }}
        >
          <div className="flex items-start space-x-6">
            <span className="text-[14px] md:text-[2.9vw] title-section font-bold uppercase">
              Our Project
            </span>
          </div>
          {/* <div className="text-[2vw]">Lorem ipsul dolor sit amet</div> */}
        </motion.div>
      </div>
      <ProjectList>
        <div className="flex flex-col md:px-[14vw]">
          {Projects.map((project, idx) => (
            <ProjectDetail
              title={project.title}
              description={project.description}
              key={idx}
            >
              <div
                className={`flex ${
                  (idx + 1) % 2 === 0
                    ? "flex-col -space-y-12"
                    : "grid grid-cols-3"
                }  w-full md:py-2`}
                ref={projectRef}
              >
                {project.projectList
                  .slice(0, projectLength)
                  .map((projectItem, itemIdx) => (
                    <React.Fragment key={itemIdx}>
                      {(idx + 1) % 2 === 0 && (
                        <CardProject
                          classNames={`${
                            (itemIdx + 1) % 2 !== 0
                              ? "z-20 flex justify-end"
                              : "z-10"
                          }`}
                          src={`${projectItem.src}`}
                          gif={`${projectItem.gif}`}
                          url={`${projectItem.url}`}
                          imageClassNames={`${
                            (itemIdx + 1) % 2 !== 0
                              ? "max-w-[60vw]"
                              : "max-w-[50vw]"
                          }`}
                          style={
                            itemIdx !== 0 && (itemIdx + 1) % 2 !== 0
                              ? { marginTop: `-5vh` }
                              : {}
                          }
                        />
                      )}
                      {(idx + 1) % 2 !== 0 && (
                        <CardProject
                          classNames={`${
                            (itemIdx + 1) % 3 === 0
                              ? "col z-20"
                              : (itemIdx + 1) % 3 === 1
                              ? "col mt-[7vw] md:mt-[5vw] mr-[2vw] z-20"
                              : "col mt-[15vw] md:mt-[15vw] mr-[2vw] z-10 hover:z-[100]"
                          }`}
                          positionX={`${
                            (itemIdx + 1) % 3 === 0
                              ? 50
                              : (itemIdx + 1) % 3 === 1
                              ? -50
                              : 0
                          }`}
                          delay={`${
                            (itemIdx + 1) % 3 === 0
                              ? 0.5
                              : (itemIdx + 1) % 3 === 1
                              ? 0.1
                              : 0.3
                          }`}
                          src={`${projectItem.src}`}
                          gif={`${projectItem.gif}`}
                          url={`${projectItem.url}`}
                          imageClassNames={`max-w-full hover:z-[100] hover:transition-all`}
                          inView={projectInView}
                        />
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </ProjectDetail>
          ))}
        </div>
      </ProjectList>
      <div className="flex justify-center">
        <RotationButton text={isShowMore === 1 ? "Show Less" : "Show More"} typeButton={"submit"} background={"bg-white"} onclick={HandlerButtonClick} />
      </div>
    </div>
  );
}
