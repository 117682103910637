import React, { useRef, useEffect } from "react";
import { motion, useInView } from "framer-motion";

export default function Card(props) {
  const videoRef = useRef();
  const projectInView = useInView(videoRef);
  useEffect(() => {
    const image = new Image();
    image.src = props.gif;
  }, [props.gif]);

  // const handlePlay = () => {
  //   videoRef.current.play().catch((error) => {
  //     // Autoplay was prevented, handle the error
  //     console.error("Autoplay failed:", error);
  //   });
  // };

  // const handleMouseLeave = () => {
  //   videoRef.current.pause();
  //   videoRef.current.currentTime = 0;
  // };
  const variants = {
    initial: {
      y: 50,
      x: parseInt(props.positionX),
      opacity: 0,
    },
    animate: {
      y: 0,
      x: 0,
      opacity: 1,
    },
  };

  const onImgOver = (e) => {
    e.currentTarget.src = props.gif;
  };

  const onImgOut = (e) => {
    e.currentTarget.src = props.src;
  };
  return (
    <React.Fragment>
      <motion.div
        className={`${props.classNames} drop-shadow-2xl`}
        initial={`initial`}
        animate={projectInView ? `animate` : `initial`}
        variants={variants}
        transition={
          projectInView ? { duration: 1, delay: props.delay } : { duration: 0 }
        }
        ref={videoRef}
      >
        <a
          href={props.url}
          className="image-container"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={props.src}
            onMouseEnter={onImgOver}
            onMouseLeave={onImgOut}
            className={`${props.imageClassNames} rounded-[0.5vw] shadow-lg`}
            style={props.style}
          ></img>
        </a>
      </motion.div>
    </React.Fragment>
  );
}
