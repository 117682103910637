import React, { useRef, useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import "./Home.css";
import SkewCard from "../../components/SkewCard/SkewCard";
import CarouselAchievement from "../../components/Achievement/Carousel";
import Services from "../../components/Services/Services";
import Project from "../../components/Project/Project";
import About from "../../components/About/About";
import Client from "../../components/OurClient/Clients";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Contacts from "../../components/Contact/Contacts";
import Inquiry from "../../components/Form/Inquiry/Inquiry";
import AddLibrary from "../../components/Helper/AddLibrary";
import DarkMode from "../../components/Toogle/Theme/DarkMode";
import Splash from "../../components/Splash/Splash";
import ImagePreloader from "../../components/Helper/ImgPreLoader";
import VideoPreloader from "../../components/Helper/VideoPreLoader";
import { ServicesData } from "../../lib/ServicesData";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope,faMapMarkerAlt,faPhone,faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import WhatsApp from "../../components/WhatsApp/WhatsApp";

library.add(fab,faEnvelope,faMapMarkerAlt,faPhone,faEnvelopeOpen)

export default function Home(props) {
  const [isVisible, setIsVisible] = useState(true);
  const sectionRefs = {
    home: useRef(null),
    service: useRef(null),
    project: useRef(null),
    about: useRef(null),
    inquiry: useRef(null)
  };
  {
    AddLibrary("https://smtpjs.com/v3/smtp.js");
  }
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    document.body.style.overflow = "hidden"; // disable body scrolling
    setTimeout(() => {
      document.body.style.overflow = "auto"; // Re-enable scrolling after hiding splash screen
    }, 3000);

    // Hide the splash screen after 5 seconds
    const timeout = setTimeout(() => {
      setIsVisible((prevState) => !prevState);
      document.body.style.overflow = "auto"; // Re-enable scrolling after hiding splash screen
    }, 3700);

    return () => clearTimeout(timeout); // Clear the timeout on component unmount
  }, []);
  return (
    <React.Fragment>
      <ImagePreloader src={"/images/Banner_2.jpg"} />
      <ImagePreloader src={"/logo/Logo_Orbit_Only.png"} />
      {ServicesData.map((service, data) => {
        <ImagePreloader src={service.url} />;
      })}
      <VideoPreloader src={"/video/headerVideo_1.mp4"} />
      {isVisible ? <Splash /> : null}
      <div className="relative">
        <Navbar sectionRefs={sectionRefs} onClick={scrollToSection} />
      </div>
      <div ref={sectionRefs.home}></div>
      <Header type="video" />
      {/* Achievements Section */}
      <SkewCard>{/* <CarouselAchievement /> */}</SkewCard>

      <div className="flex w-full overflow-hidden" ref={sectionRefs.about}>
        <About></About>
      </div>

      {/* Services Section */}
      <div
        className="flex w-full overflow-hidden my-8"
        ref={sectionRefs.service}
      >
        <Services />
      </div>
      {/* Project Section */}
      <div
        className="flex w-full overflow-hidden my-8"
        ref={sectionRefs.project}
      >
        <Project></Project>
      </div>
      {/* Client Section */}
      <div className="flex w-full overflow-hidden my-8">
        <Client></Client>
      </div>
      {/* About Section */}
      <div className="vertical-line-orange">
        {/* <div className="flex w-full overflow-hidden mt-8">
          <Contacts></Contacts>
        </div> */}
      </div>

      {/* Inquiry Section */}
      <div>
        <Inquiry ref={sectionRefs.inquiry} />
      </div>
      <Footer sectionRefs={sectionRefs} onClick={scrollToSection} />
      <WhatsApp />
    </React.Fragment>
  );
}
