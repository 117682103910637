import { motion } from "framer-motion";
import DarkMode from "../Toogle/Theme/DarkMode";

export default function Navbar(props) {
  return (
    <motion.div
      className={`${props.isOpen ? "fixed" : "hidden"} ${
        props.isVisible ? `top-15vw` : `top-[20vw]`
      } bg-[#000000] text-white left-0 w-full h-screen z-50`}
      initial={{ opacity: 0 }}
      animate={{
        opacity: props.isOpen ? 1 : 0,
      }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex justify-center w-8/12 mx-auto">
        <ul className="text-center space-y-[5vw]">
          {props.menu.map((men, index) => (
            <li key={index}>
              <a
                onClick={(event) => {
                  men.ref ? props.onClick(men.ref) : event.preventDefault();
                  props.setIsOpen((prevState) => !prevState);
                  document.body.classList.remove("overflow-hidden");
                }}
                className="hover:cursor-pointer hover:text-[#3761AD] text-[8vw]"
                href={`javascript:;`}
              >
                {men.title}
              </a>
            </li>
          ))}
          <li onClick={() => props.setIsOpen((prevState) => !prevState)}>
            <div className="w-fit h-fit mx-auto">
              <DarkMode/>
            </div>
          </li>
        </ul>
      </div>
    </motion.div>
  );
}
