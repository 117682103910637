import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import NavbarMobile from "./NavbarMobile";
import DarkMode from "../../components/Toogle/Theme/DarkMode";

export default function Navbar(props) {
  const menu = [
    {
      url: "/",
      title: "Home",
      ref: props.sectionRefs.home,
    },
    {
      url: "/service",
      title: "Service",
      ref: props.sectionRefs.service,
    },
    {
      url: "/project",
      title: "Project",
      ref: props.sectionRefs.project,
    },
    {
      url: "/about",
      title: "About",
      ref: props.sectionRefs.about,
    },
  ];
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [isVisible, setIsVisible] = useState(true);
  const [isAtTop, setIsAtTop] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setIsVisible(currentScrollPos < prevScrollPos);
    setIsAtTop(currentScrollPos < 300);
    setPrevScrollPos(currentScrollPos);
  };

  // Attach scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
  }, [isOpen]);

  return (
    <React.Fragment>
      <motion.nav
        className={`navbar fixed top-0 left-0 w-full z-50 transition-all ${
          isVisible ? "translate-y-0" : "-translate-y-full"
        }`}
        initial={{ y: -100 }}
        animate={{ y: isVisible ? 0 : -100 }}
        transition={{ duration: 0.3, ease: `easeInOut` }}
      >
        <motion.div
          className={`grid grid-cols-3 ${
            isVisible && !isAtTop ? `py-2` : `py-6`
          } px-10 md:px-20 ${
            isAtTop ? isOpen ? "bg-[#000000]" : "md:bg-opacity-0" : "bg-[#000000c0]"
          } text-[#fff] font-bold z-10`}
          initial={{ paddingTop: `2rem`, paddingBottom: `2rem` }}
          animate={{
            paddingTop: isVisible && !isAtTop ? `1.5rem` : `2rem`,
            paddingBottom: isVisible && !isAtTop ? `1.5rem` : `2rem`,
          }}
          transition={{ duration: 0.3, ease: `easeInOut` }}
        >
          <div className="col">
            <div className="flex items-center">
              <NavLink to={`/`} className="flex">
                <img
                  src="/logo/Logo_Orbit_White.png"
                  alt="LogoBlessink"
                  className="w-[40vw] md:w-[15vw]"
                />
                {/* <img
                  src="/logo/Logo_Blessink_2_1.png"
                  alt="BlessinkStudio"
                  className="w-[15vw] md:w-[10vw]"
                /> */}
              </NavLink>
            </div>
          </div>
          <div className="hidden md:flex justify-center items-center md:col">
            <ul className="flex md:space-x-4 min-[1440px]:space-x-10 lg:text-[1.3rem] md:text-[1rem]">
              {menu.map((men, index) => (
                <li key={index} className="px-2">
                  {/* <NavLink to={men.url} key={index}>
                {({ isActive }) => (
                  <span
                  // className={
                  //   isActive ? "text-[#3761AD]" : "hover:text-[#3761AD]"
                  // }
                  >
                    {men.title}
                  </span>
                )}
              </NavLink> */}
                  <a
                    onClick={(event) => {
                      props.onClick(men.ref);
                    }}
                    className="hover:cursor-pointer hover:text-[#3761AD]"
                    href={`javascript:;`}
                  >
                    {men.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="md:hidden flex justify-end items-center col-end-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
              onClick={() => setIsOpen((prevState) => !prevState)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>
          <div className="flex justify-end items-center md:flex hidden">
            <DarkMode />
          </div>
        </motion.div>
        <div className="relative">
          <NavbarMobile
            menu={menu}
            isVisible={isVisible}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClick={props.onClick}
          />
        </div>
      </motion.nav>
    </React.Fragment>
  );
}
