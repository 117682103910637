import "./RotationButton.css";

export default function RotationButton(props) {
    return (
        <div>
          <button  type={props.typeButton} onClick={props.onclick} className="rotated-btn rotated-btn-small rotated-btn-box">
            <span className={`rotated-btn-box-border-left theme-color`}></span>
            <span className={`rotated-btn-box-border-right theme-color`}></span>
            <span className={`rotated-btn-box-border-top-1 theme-color`}></span>
            <span className={`rotated-btn-box-border-top-2 theme-color`}></span>
            <span className={`rotated-btn-box-border-bottom-1 theme-color`}></span>
            <span className={`rotated-btn-box-border-bottom-2 theme-color`}></span>
            <span className="rotated-btn-text title-section font-bold">{props.text}</span>
          </button>
        </div>
    )
}