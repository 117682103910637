export const Projects = [
  {
    title: `filmography`,
    description: `Lorem ipsum dolor sit amet`,
    projectList: [
      // {
      //   gif: `/images/project/Gatot-Kaca.gif`,
      //   src: `/images/project/gatot-kaca.png`,
      //   url: `https://www.youtube.com/watch?v=fX0mcpJeMpo`,
      // },
      {
        gif: `/images/project/(01 )GRABEXPRESS.gif`,
        src: `/images/project/COMPRESSED/(01 )GRABEXPRESS.png`,
        url: `https://vt.tiktok.com/ZSFrKxwmw/`,
      },
      {
        gif: `/images/project/(02) GANJAR-MAHFUD.gif`,
        src: `/images/project/COMPRESSED/(02) GANJAR-MAHFUD.png`,
        url: `https://vt.tiktok.com/ZSFrKvpWg/`,
      },
      {
        gif: `/images/project/(03) CHAINSAWMAN.gif`,
        src: `/images/project/COMPRESSED/(03) CHAINSAWMAN.png`,
        url: `https://vt.tiktok.com/ZSFrK4WBT/`,
      },
      {
        gif: `/images/project/(04) JNE.gif`,
        src: `/images/project/COMPRESSED/(04) JNE.png`,
        url: `https://vt.tiktok.com/ZSFrKs5yH/`,
      },
      {
        gif: `/images/project/(05) THE CREATOR.gif`,
        src: `/images/project/COMPRESSED/(05) THE CREATOR.png`,
        url: `https://youtube.com/shorts/ZRrY-AVM8Q8?si=S3lHMAZJWNjp9iVf`,
      },
      {
        gif: `/images/project/(06) CAFFINO.gif`,
        src: `/images/project/COMPRESSED/(06) CAFFINO.png`,
        url: `https://www.instagram.com/reel/Czgi3jWvEur/?igsh=YWc5eTR3aGF3NWlt`,
      },
      {
        gif: `/images/project/(07) RF ONLINE.gif`,
        src: `/images/project/COMPRESSED/(07) RF ONLINE.png`,
        url: `https://www.instagram.com/reel/C2T9npErSRY/?igsh=MXFzeWowYXdxeXZyMg==`,
      },
      {
        gif: `/images/project/(08) MOBIL.gif`,
        src: `/images/project/COMPRESSED/(08) MOBIL.png`,
        url: `https://www.tiktok.com/@cgsick_/video/7276408310503345414?is_from_webapp=1&sender_device=pc&web_id=7334362281331377665`,
      },
      {
        gif: `/images/project/(09) BALIHO.gif`,
        src: `/images/project/COMPRESSED/(09) BALIHO.png`,
        url: `https://vt.tiktok.com/ZSFhd7X1F/`,
      },
      {
        gif: `/images/project/(10) LOKI.gif`,
        src: `/images/project/COMPRESSED/(10) LOKI.png`,
        url: `https://www.youtube.com/shorts/KA5Ts81tW_E`,
      },
      {
        gif: `/images/project/(11) BSI.gif`,
        src: `/images/project/COMPRESSED/(11) BSI.png`,
        url: `https://www.instagram.com/reel/C1q-12QB6XV/?igsh=MXdoODcyeXVwZnFzMQ==`,
      },
      {
        gif: `/images/project/(12) AMIN.gif`,
        src: `/images/project/COMPRESSED/(12) AMIN.png`,
        url: `https://www.instagram.com/reel/C2ZIbLny3QU/?igsh=MWV0bmwxOWZwcGRrdg==`,
      },
      {
        gif: `/images/project/(13) CYBERPUNK.gif`,
        src: `/images/project/COMPRESSED/(13) CYBERPUNK.png`,
        url: `https://vt.tiktok.com/ZSFhdCjDR/`,
      },
      {
        gif: `/images/project/(14) TROLI 12.12.gif`,
        src: `/images/project/COMPRESSED/(14) TROLI 12.12.png`,
        url: `https://youtube.com/shorts/xInSSp65C8E?si=qutI6wp_yuqNomUl`,
      },
      {
        gif: `/images/project/(15) SPIDERMAN.gif`,
        src: `/images/project/COMPRESSED/(15) SPIDERMAN.png`,
        url: `https://www.instagram.com/reel/Cy8LIt9LZHo/?igsh=MTc5eW1samp3eXFjNg==`,
      },
    ],
  },
];
