import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
export default function Header(props) {
  const [isLoaded, setLoaded] = React.useState(false);
  const navigate = useNavigate();

  const handlerVideo = () => {
    setLoaded(true);
  };
  const handleClick = () => {
    // Redirect to another route
    navigate("/inquiry");
  };
  return (
    <React.Fragment>
      {props.type === "video" && (
        <div className="w-full h-[100vh] overflow-hidden relative">
          <AnimatePresence>
            <motion.div
              className="absolute top-0 left-0 w-full h-full z-20"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, ease: `easeInOut` }}
              key={`fdd-sas`}
            >
              <div className="w-full h-full flex justify-center items-center text-white">
                <div className="text-header w-5/6 md:w-4/6 relative">
                  <div className="text-[8vw] md:text-[6vw] lg:text-[6vw] text-right font-semibold">
                    VFX & CGI SERVICE
                  </div>
                  <div className="text-[6vw] md:text-[4vw] lg:text-[4vw] w-full font-semibold">
                    FOR ALL NEEDS
                  </div>
                  <div className="text-[4vw] md:text-[2vw] lg:text-[2vw] flex justify-between font-semibold">
                    Powered by: CGsick
                    <button
                      onClick={handleClick}
                      className=" bg-[#fb00fb] rounded-full py-[1vw] px-[6vw] hidden md:inline-block md:text-[2vw] font-bold"
                    >
                      {/* <div className="text-transparent bg-clip-text bg-gradient-to-r from-[#008BC0] to-[#0058B9]"> */}
                      GO TO BRIEF
                      {/* </div> */}
                    </button>
                  </div>
                  <div className="inline-block md:hidden w-full text-center mt-5">
                    <button
                      onClick={handleClick}
                      className="bg-[#fb00fb] rounded-full py-[2vw] px-[8vw] md:text-[2vw] font-bold"
                    >
                      {/* <div className="text-transparent bg-clip-text bg-gradient-to-r from-[#008BC0] to-[#0058B9]"> */}
                      GO TO BRIEF
                      {/* </div> */}
                    </button>
                  </div>
                </div>
                {/* <img
                  src="/logo/Logo_Orbit_White.png"
                  alt="LogoBlessink"
                  className="w-[32vw] md:w-[20vw]"
                /> */}
                {/* <p className="text-xl md:text-2xl lg:text-3xl font-bold">
                  The Orbit
                </p> */}
                {/* <img
                  src="/logo/Sm-Text-blessink.png"
                  alt="BlessinkStudio"
                  className="w-[23vw] md:w-[11vw]"
                /> */}
              </div>
            </motion.div>
          </AnimatePresence>
          {!isLoaded && (
            <img
              className="w-full h-full object-cover"
              src="/video/Thumbnail_Video.png"
            />
          )}
          <video
            autoPlay
            muted
            playsInline
            loop
            className="w-full h-full object-cover"
            preload="auto"
            onLoadedData={handlerVideo}
          >
            <source src="/video/headerVideo_1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="w-full h-full object-cover absolute left-0 top-0 bg-[#00000060]"></div>
        </div>
      )}
      {props.type === "static" && (
        <div>
          <div className="relative">
            <img src={props.url} alt={props.url} />
            <div className="absolute top-1/2 left-10 -translate-y-1/2 text-[#fff]">
              <div>
                <h1 className="text-3xl font-bold">OUR SERVICE</h1>
                <span className="text-xl">
                  Exploration and innovation are essential to Blessink Studio
                  visual effects work
                </span>
              </div>
              <div className="flex space-x-4">
                <div>Button 1</div>
                <div>Button 2</div>
                <div>Button 3</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
