import React, { useEffect, useState } from "react";

const VideoPreloader = ({ src }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const video = new Image();
    video.src = src;
    video.onload = () => {
      setIsLoaded(true);
    };
    window[src] = video;
    return () => {
      // Clean up if the component is unmounted
      video.onload = null;
      window[src] = null;
    };
  }, [src]);

  return null
};

export default VideoPreloader;
