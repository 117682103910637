export const SocialMedias = [
  {
    url: "https://www.youtube.com/@CGsick ",
    image: "/icon/youtube.png",
    icon:"fab fa-youtube"
  },
  {
    url: "https://www.linkedin.com/in/orbit-universe-2352382ab/ ",
    image: "/icon/linkedin.png",
    icon:"fab fa-linkedin"
  },
  {
    url: "https://www.instagram.com/cgsick/",
    image: "/icon/instagram.png",
    icon:"fab fa-instagram"
  },
  {
    url: "https://www.tiktok.com/@cgsick_",
    image: "/icon/fiverr.png",
    icon:"fab fa-tiktok"
  },
  // {
  //   url: "https://www.imdb.com/name/nm12046191/",
  //   image: "/icon/imdb.png",
  //   icon:"fab fa-imdb"
  // },
];
