import React from "react";
import Abouts from "./Abouts";

export default function About(props) {
  return (
    <div className="flex flex-col px-10 md:px-[14vw] w-full relative">
      <div className="flex space-x-4 items-center">
        <div>
          <div className="flex items-start space-x-6 my-7">
            <span className="text-[14px] md:text-[2.9vw] font-bold uppercase title-section">
              <strong>WE ARE COMMITED TO CREATE THE BEST WORK</strong>
            </span>
          </div>
        </div>
      </div>
      <Abouts />
    </div>
  );
}
