import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "./Inquiry.css";
import { useNavigate } from "react-router-dom";

export default function Inquiry() {

  var navigate = useNavigate()
  const handleClick = () => {
    // Redirect to another route
    navigate('/inquiry');
  };
  return (
    <main className="bg-form font-montserrat py-8 md:px-[14vw]">
      <div className="text-[7vw] md:text-[5vw] md:ml-0 ml-[5vw] md:mb-0 mb-[5vw] text-white font-bold">
        <label>
          LET'S WORK TOGETHER
        </label>
      </div>
      <div className="flex md:justify-between flex-col md:flex-row md:items-center">
        <div className="flex md:gap-5 md:flex-row flex-col md:w-full w-1/2 mx-auto md:gap-y-0 gap-y-5">
          <button onClick={handleClick} className="text-white bg-[#fb00fb] rounded-lg py-[1vw] px-[6vw] font-bold">
            GO TO BRIEF
          </button>
          <a href="/file/Company Profile Orbit Universe.pdf" target='_blank' rel='noopener noreferrer' className="text-white border rounded-lg py-[1vw] px-[6vw]">
            OUR PRESENTATION
          </a>
        </div>
      </div>
    </main>
  );
}
